import React from "react"
import styles from "./articles.module.css"
import Title from "../title/title";
import Article1 from "./images/article1.jpg";
import Article2 from "./images/article2.jpg";
import Article3 from "./images/article3.jpg";
import Article4 from "./images/article4.jpg";
import Article5 from "./images/article5.jpg";
import Article6 from "./images/article6.jpg";
import Article from "./article/article";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import ModalRouterButton from "../buttons/modal-router-button/modal-router-button";

const Articles = () => {

    const rootClasses  = 'row expanded ' + styles.articles;

    const data = useStaticQuery(graphql`
      query {
        pointer: file(relativePath: { eq: "pointer-icon.png" }) {
          childImageSharp {
            fixed(width: 41, height: 44) {
              ...GatsbyImageSharpFixed
            }
          }
        },
      }
    `);

    return (
        <div className={rootClasses} id={"articles"}>
            <div className="column small-12">
                <div className="section-wrapper">
                    <div className="section">
                        <Title text={'Статьи'} color={'#393939'}/>
                        <div className="row expanded" style={{marginTop: '30px'}}>
                            <div className="columns small-12 medium-6 large-4">
                                <ModalRouterButton path={"shpaklevka"} content={
                                    <div className={styles.pointerWrapper} >
                                        <Article background={Article1} text={'Как правильно шпаклевать стены'}/>
                                        <div className={styles.pointer}>
                                            <Img fixed={data.pointer.childImageSharp.fixed} />
                                        </div>
                                    </div>
                                }/>
                            </div>
                            <div className="columns small-12 medium-6 large-4">
                                <ModalRouterButton path={"laminat"} content={
                                    <Article background={Article2} text={'Как выбрать ламинат по цвету и узору'}/>
                                }/>
                            </div>
                            <div className="columns small-12 medium-6 large-4">
                                <ModalRouterButton path={"10-trendov-dizaina"} content={
                                    <Article background={Article3} text={'10 трендов дизайна квартир'}/>
                                }/>
                            </div>
                            <div className="show-for-large" style={{marginBottom: '65px'}}  />
                            <div className="columns small-12 medium-6 large-4">
                                <ModalRouterButton path={"potolki"} content={
                                    <Article background={Article4} text={'Секреты натяжных потолков'}/>
                                }/>
                            </div>
                            <div className="columns small-12 medium-6 large-4">
                                <ModalRouterButton path={"dizain-vannaya-komnata"} content={
                                    <Article background={Article5} text={'Дизайны ванных комнат топ 2020 года'}/>
                                }/>
                            </div>
                            <div className="columns small-12 medium-6 large-4">
                                <ModalRouterButton path={"plan-remonta"} content={
                                    <Article background={Article6} text={'Как спланировать ремонт в новой квартире'}/>
                                }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Articles
