import React from "react"
import Modal from 'react-modal';
import styles from "../button.module.css";
import useWindowSize from "../../mobile-menu/window-dimensions";

const ModalButton = ({text, btnClass, content}) => {

    const {width, height} = useWindowSize();
    const isMobile = width < 640;

    const customStyles = {
        overlay: {
            zIndex: 1001,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        content: {
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            overflow: 'visible',
            padding: 0,
            border: 'none',
            width: isMobile ? '90%' : 'auto'
        }
    };

    Modal.setAppElement('#___gatsby');

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {

    }

    function closeModal(){
        setIsOpen(false);
    }

    return (
        <div>
            <button className={btnClass ? btnClass : styles.button} onClick={openModal}>{text}</button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >

                <div className="request-modal">
                    <a className="close-modal" onClick={closeModal}>
                        &times;
                    </a>
                    {content}
                </div>
            </Modal>
        </div>
    );
};


export default ModalButton;
