import React from "react"
import styles from "./title.module.css"

const Title = ({text, color}) => {

    return (
        <div className="row expanded collapse">
            <div className="column text-center" style={{position: "relative"}}>
                <span className={styles.stick}/><span className={styles.text} style={{color: color}}>{text}</span>
            </div>
        </div>
    );
};

export default Title
