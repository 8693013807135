import React from "react"
import styles from "../button.module.css"

const ActionButton = ({text, action}) => {

    return (
        <button className={styles.button} onClick={action}>{text}</button>
    );
};


export default ActionButton
