import React from "react"
import styles from "./option.module.css"

const Option = ({options, handler}) => {

    const [selectedId, setSelectedId] = React.useState();

    function select(option) {
        options
            .map(opt => {
                if (opt.id === option.id) {
                    opt.selected = true;
                    setSelectedId(opt.id);
                } else {
                    opt.selected = false;
                }
            });
    }

    function getSelectedId() {
        if (selectedId) {
            return selectedId
        } else {
            return options.find(opt => opt.selected).id;
        }
    }

    return (
        options.map(opt => {
            const isSelected = opt.id === getSelectedId();
            const classes = styles.option + ' ' + (isSelected? styles.selected : '');
            return (
                <div>
                    <div key={opt.id} className={classes} onClick={() => {
                        select(opt);
                        handler(opt.id);
                    }}>
                        {opt.text}
                    </div>
                </div>
            )
        })
    );
};


export default Option
