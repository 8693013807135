import Img from "gatsby-image";
import styles from "./contacts.module.css";
import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Phones from "./phones/phones";

const Contacts = ({isBlack = false}) => {
    const data = useStaticQuery(graphql`
    query {
      email: file(relativePath: { eq: "email-icon.png" }) {
        childImageSharp {
          fixed(width: 19, height: 14) {
          ...GatsbyImageSharpFixed
          }
        }
      },
      emailBlack: file(relativePath: { eq: "email-icon-black.png" }) {
        childImageSharp {
          fixed(width: 19, height: 14) {
          ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

    const emailIcon = isBlack ? data.emailBlack.childImageSharp.fixed : data.email.childImageSharp.fixed;
    const textColor = isBlack ? "#393939" : "#fff";

    return (
        <div>
            <Phones isBlack={isBlack}/>
            <div className="row align-middle">
                <div className="columns small-3 large-4">
                    <Img className={styles.emailIcon} fixed={emailIcon} />
                </div>
                <div className="columns small-9 large-8">
                    <a className={styles.email} style={{color: textColor}} href="mailto:info@complexstroy.by">info@complexstroy.by</a>
                </div>
            </div>
        </div>
    );
};

export default Contacts
