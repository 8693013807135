import React from "react"
import styles from "./phones.module.css";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Phones = ({isBlack = false}) => {
    const data = useStaticQuery(graphql`
    query {
      mtsImage: file(relativePath: { eq: "mts.png" }) {
        childImageSharp {
          fixed(width: 37, height: 9, quality: 100) {
          ...GatsbyImageSharpFixed
        }
        }
      },
      a1Image: file(relativePath: { eq: "a1.png" }) {
        childImageSharp {
          fixed(width: 15, height: 17, quality: 100) {
          ...GatsbyImageSharpFixed
        }
        }
      },
    }
  `);

    const textColor = isBlack ? "#393939" : "#fff";

    return (
        <div>
            <div className="row align-middle">
                <div className="columns small-3 large-4">
                    <Img className={styles.img} fixed={data.mtsImage.childImageSharp.fixed} />
                </div>
                <div className="columns small-9 large-8">
                    <a className={styles.phone} style={{color: textColor}} href="tel: +375297792417">+375 (29) 779-24-17</a>
                </div>
            </div>
            <div className="row align-middle">
                <div className="columns small-3 large-4">
                    <Img className={styles.img} fixed={data.a1Image.childImageSharp.fixed} />
                </div>
                <div className="columns small-9 large-8">
                    <a className={styles.phone} style={{color: textColor}} href="tel: +375447792420">+375 (44) 779-24-20</a>
                </div>
            </div>
        </div>
    );
};


export default Phones
