import React from "react"
import styles from "./details.module.css"
import Img from "gatsby-image";

const Details = ({index, header, text, image}) => {

    return (
        <div className="column small-12 medium-4 large-3">
            <div className={styles.details}>
                <div className={styles.index}>
                    <span>{index}</span>
                    <div className={styles.icon}>
                        <Img fixed={image} />
                    </div>
                </div>
                <div className={styles.headerText}>
                    {header}
                </div>
                <div className={styles.text}>
                    {text}
                </div>
            </div>
        </div>
    );
};

export default Details
