import React from "react"
import { Link } from 'gatsby'

const ModalRouterButton = ({content, path}) => {

    const link = `/${path}`;
    return (
        <Link
            to={link}
            state={{
                modal: true
            }}
        >
            {content}
        </Link>
    );
};


export default ModalRouterButton
