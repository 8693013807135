import React from "react"
import styles from"./social.module.css"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Social = () => {

    const data = useStaticQuery(graphql`
      query {
        social: file(relativePath: { eq: "social.png" }) {
          childImageSharp {
            fixed(width: 24, height: 23) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        telegram: file(relativePath: { eq: "telegram.png" }) {
          childImageSharp {
            fixed(width: 24, height: 21) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        watsup: file(relativePath: { eq: "watsup.png" }) {
          childImageSharp {
            fixed(width: 24, height: 25) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        viber: file(relativePath: { eq: "viber.png" }) {
          childImageSharp {
            fixed(width: 24 height: 26) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `);

    const social = data.social.childImageSharp.fixed;
    const telegram = data.telegram.childImageSharp.fixed;
    const watsup = data.watsup.childImageSharp.fixed;
    const viber = data.viber.childImageSharp.fixed;

    const [opened, setOpened] = React.useState(false);

    function toggleOpened() {
        setOpened(!opened);
    }

    return (
        <div className={styles.social}>
            <div className={opened ? `${styles.display} ${styles.visible}` : `${styles.display} ${styles.hidden}`}>
                <a href={'https://t.me/complexstroyby'} >
                    <div className={`${styles.telegram} ${styles.button}`}>
                        <Img fixed={telegram} />
                    </div>
                </a>
                <a href={"viber://chat?number=%2B375297792417"}>
                    <div className={`${styles.viber} ${styles.button}`}>
                        <Img fixed={viber} />
                    </div>
                </a>
            </div>
            <div className={`${styles.control} ${styles.button}`} onClick={toggleOpened}>
                <Img fixed={social} />
            </div>
        </div>
    );
};

export default Social
