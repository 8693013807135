import React from "react"
import styles from "./header.module.css"
import MenuItems from "./menuItems/menuItems";
import Phones from "../contacts/phones/phones";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Logo from "../logo/logo";

const Header = () => {
    const classes = "row expanded show-for-large align-middle " + styles.menu;
    return (
        <div className={classes}>
            <div className="columns small-3">
                <AnchorLink href={'#main'}>
                    <Logo centered={true}/>
                </AnchorLink>
            </div>
            <div className="columns small-6">
                <MenuItems/>
            </div>
            <div className="columns small-3">
                <Phones/>
            </div>
        </div>
    );
};


export default Header
