import React from "react"
import styles from "./footer.module.css"
import Logo from "../logo/logo";
import Contacts from "../contacts/contacts";

const Footer = () => {

    const rootClasses = 'row expanded ' + styles.footer;

    return (
        <div className={rootClasses}>
            <div className="column small-12">
                <div className="section-wrapper">
                    <div className="section">
                        <div className="row expanded show-for-small-only">
                            <div className="column small-12 text-center">
                                <Logo centered={true}/>
                            </div>
                        </div>
                        <div className="row expanded">
                            <div className="columns small-12 medium-6 medium-text-left small-text-center small-order-2 medium-order-1">
                                <div className="row hide-for-small-only">
                                    <div className="column small-12">
                                        <Logo/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column small-12">
                                        <div className={styles.text}>
                                            <span>ЧП «Нарвал-Север»</span>
                                            <span>УНП 691536921</span>
                                            <span>РБ 223070 Минский р-н, Михановичский с/с, д. Дубовый Лес,</span>
                                            <span>комн. 22 административного здания производственной базы ООО СП «Спецкомплектимпэкс»</span>
                                            <span>р/с (BYN) BY40BPSB30121581180179330000</span>
                                            <span>в ОАО «БПС-Сбербанк», г. Минск, ЦБУ №707, код BPSBBY2X</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columns small-12 small-order-1 medium-6 medium-order-2 large-4 large-offset-2 large-order 2">
                                <div className={styles.contacts}>
                                    <p>Контакты</p>
                                    <Contacts/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer
