import React from "react"
import styles from "./about.module.css"
import Title from "../title/title";
import Details from "./details/details";
import {graphql, useStaticQuery} from "gatsby";
import ModalButton from "../buttons/modal-button/modal-button";
import Form from "../form/form";
import Img from "gatsby-image";

const About = () => {

    const data = useStaticQuery(graphql`
      query {
        comment: file(relativePath: { eq: "comment.png" }) {
          childImageSharp {
            fixed(width: 64, height: 61) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        lamp: file(relativePath: { eq: "lamp.png" }) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        key: file(relativePath: { eq: "key.png" }) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        creative: file(relativePath: { eq: "creative.png" }) {
          childImageSharp {
            fixed(width: 67, height: 67) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        warehouse: file(relativePath: { eq: "warehouse.png" }) {
          childImageSharp {
            fixed(width: 63, height: 63) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        price: file(relativePath: { eq: "price.png" }) {
          childImageSharp {
            fixed(width: 59, height: 59) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        worker: file(relativePath: { eq: "worker.png" }) {
          childImageSharp {
            fixed(width: 77, height: 58) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        pay: file(relativePath: { eq: "pay.png" }) {
          childImageSharp {
            fixed(width: 60, height: 57) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        swipe: file(relativePath: { eq: "swipe.png" }) {
          childImageSharp {
            fixed(width: 46, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `);

    const aboutClasses = 'row expanded ' + styles.about;
    const detailsClasses = 'row expanded ' + styles.detailsWrapper;

    let commentImage = data.comment.childImageSharp.fixed;
    let lampImage = data.lamp.childImageSharp.fixed;
    let keyImage = data.key.childImageSharp.fixed;
    let creativeImage = data.creative.childImageSharp.fixed;
    let warehouseImage = data.warehouse.childImageSharp.fixed;
    let priceImage = data.price.childImageSharp.fixed;
    let workerImage = data.worker.childImageSharp.fixed;
    let payImage = data.pay.childImageSharp.fixed;


    return (
        <div className={aboutClasses} id={"about"}>
            <div className="column small-12">
                <div className="section-wrapper">
                    <div className="section">
                        <Title text={'Почему мы'} color={'#FFF'} />
                        <div className="show-for-small-only" style={{position: "relative"}}>
                            <div className={styles.swipeIcon}>
                                <Img fixed={data.swipe.childImageSharp.fixed} />
                            </div>
                        </div>
                        <div className={detailsClasses}>
                            <Details
                                index={'1'}
                                header={'Бесплатная консультация'}
                                text={'Мы бесплатно проконсультируем вас, обсудим все нюансы вашего обьекта.'}
                                image={commentImage}/>
                            <Details
                                index={'2'}
                                header={'Любая сложность работ'}
                                text={'Берёмся за любую сложность. От новостройки до вторичной квартиры или даже этажа.'}
                                image={lampImage}/>
                            <Details
                                index={'3'}
                                header={'Ремонт под ключ'}
                                text={'Наше приемущество что мы можем осуществить все работы под ключ.'}
                                image={keyImage}/>
                            <Details
                                index={'4'}
                                header={'Свои дизайнеры'}
                                text={'Наш дизайнер поможет вам найти решение и визуализировать.'}
                                image={creativeImage}/>
                            <Details
                                index={'5'}
                                header={'Оптовые цены на материалы'}
                                text={'У нас партнёрские отношения с поставщиками на строительные и декоративные материалы.'}
                                image={warehouseImage}/>
                            <Details
                                index={'6'}
                                header={'Контрактная цена'}
                                text={'После замера, дизайна, и составления списка работ. Цена не поменяется.'}
                                image={priceImage}/>
                            <Details
                                index={'7'}
                                header={'Все мастера'}
                                text={'У нас команда из всех возможных мастеров своего дела. С большим опытом.'}
                                image={workerImage}/>
                            <Details
                                index={'8'}
                                header={'Рассрочка'}
                                text={'У нас предусматривается и рассрочка на наши работы. Наш менеджер поможет с этим.'}
                                image={payImage}/>
                        </div>
                        <div className="row expanded hide-for-small-only">
                            <div className="column small-12 text-center">
                                <div className={styles.buttonWrapper}>
                                    <ModalButton
                                        text={'Заказать консультацию'}
                                        content={<Form isPopup={true}/>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About
