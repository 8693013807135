import React from "react"

import Container from "../components/container/container"
import SEO from "../components/seo"
import YM from "../components/analytics/ym"
import Header from "../components/header/header";
import Hero from "../components/hero/hero";
import Gallery from "../components/gallery/gallery";
import About from "../components/about/about";
import Calculator from "../components/calculator/calculator";
import Services from "../components/services/services";
import Feedback from "../components/feedback/feedback";
import ContactUs from "../components/contact-us/contact-us";
import Articles from "../components/articles/articles";
import Footer from "../components/footer/footer";
import Social from "../components/social/social";
import MobileMenu from "../components/mobile-menu/mobile-menu";

const IndexPage = () => (
  <Container>
    <SEO
        title="complexstroy.by | Ремонт и отделка квартир в Минске"
        description="complexstroy.by, complexstroy, complex stroy, ремонт квартир, Минск, комплексстрой, комплекс строй, ремонт квартир под ключ"
    />
    <YM />
    <Header />
    <MobileMenu />
    <Hero />
    <Gallery />
    <About />
    <Calculator/>
    <Services/>
    {/*<Feedback/>*/}
    <ContactUs/>
    <Articles/>
    <Footer/>
    <Social/>
  </Container>
);

export default IndexPage
