import React from "react"
import styles from "./contact-us.module.css"
import Form from "../form/form";

const ContactUs = () => {

    const rootClasses = 'row expanded ' + styles.contactUs;

    return (
        <div className={rootClasses}>
            <div className="column small-12 medium-8 medium-offset-2 text-center">
                <div className="section-wrapper">
                    <div className="section">
                        <div className={styles.formWrapper}>
                            <Form isPopup={false}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs
