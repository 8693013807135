import React from "react"
import styles from "./services.module.css"
import Title from "../title/title";
import Service from "./service/service";

const Services = () => {

    const rootClasses = 'row expanded ' + styles.services;

    return (
        <div className={rootClasses} id={"services"}>
            <div className="column small-12">
                <div className="section-wrapper">
                    <div className="section">
                        <Title text={'Наши услуги'} color={'#fff'}/>
                        <div className={styles.serviceWrapper}>
                            <div className={"row expanded"}>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Работы по демонтажу перегородок, полов, конструкций, проемов и прочее. Вынесем и вывезем мусор после демонтажа'} index={'1'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Установим перегородки из блоков (силикат, гипс, керамзит), кирпича, бруса, гипсокартона'} index={'2'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Выравнивание стен, потолков, как штукатуркой, так и гипсокартоном'} index={'3'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Выравнивание оснований полов: стяжки, самонивелирующие смеси, плиты ГВЛ'} index={'4'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Выполним финальную отделку стен и потолков: подготовка под окраску и окраска, декоративные штукатурки, обои всех видов'} index={'5'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Работа с гипсокартоном любой сложности (потолки плоские, многуровневые, конструкции, инсталляции, короба с подсветками)'} index={'6'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Устройство финишных напольных покрытий: паркетная доска, ламинированный пол, рулонные покрытия, наливные покрытия'} index={'7'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Натяжные потолки: матовые, глянцевые, сложные двухуровневые'} index={'8'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Расчет и прокладка инженерных сетей: водопровода, канализации, отопления. Монтаж сантехнических приборов и оборудования'} index={'9'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Проектирование, расчет и весь перечень электромонтажных работ. Силовая и слаботочная разводка. Модернизация существующих сетей, переносы, замена электроустановочных изделий, освещения.'} index={'10'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Устройство декоративных панелей из гипса, наборные панели из деревянного бруса, доски'} index={'11'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Облицовка плиткой различного формата и любой сложности, от керамогранита до мозайки'} index={'12'} />
                                </div>
                                <div className="columns small-12 medium-6 large-4">
                                    <Service text={'Установка дверных блоков, межкомнатных и входных, устройство порталов и откосов. Дверные блоки на заказ из стекла, ПВХ и алюминия, двери-гармошки, невидимки'} index={'13'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services
