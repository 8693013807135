import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./logo.module.css"
import Img from "gatsby-image"

const Logo = ({black = false, small = false, centered = false}) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 30) {
          ...GatsbyImageSharpFixed_noBase64
          }
        }
      },
      logoBlackSmall: file(relativePath: { eq: "logo-black.png" }) {
        childImageSharp {
          fixed(width: 22) {
          ...GatsbyImageSharpFixed_noBase64
          }
        }
      },
    }
  `);

  const logoImage = black && small ? data.logoBlackSmall.childImageSharp.fixed : data.logo.childImageSharp.fixed;
  const textColor = black ? '#393939' : '#fff';
  const mainTextStyles = small ? {fontSize: 16, lineHeight: '16px'}  : {fontSize: 22, lineHeight: '22px'};
  const secondaryTextStyles = small ? {fontSize: 10} : {fontSize: 13} ;

  return (
      <div className={styles.logoWrapper} style={{justifyContent: centered ? "center" : "left"}}>
        <div className={styles.imageWrapper}>
          <Img fixed={logoImage} />
        </div>
        <div className={styles.textWrapper} style={{color : textColor}}>
          <p className={styles.mainText} style={mainTextStyles}>complexstroy.by</p>
          <p className={styles.secondaryText} style={secondaryTextStyles}>Ремонт и отделка квартир</p>
        </div>
      </div>
  );
};

export default Logo
