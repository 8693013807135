import React from "react"
import styles from "./hero.module.css"
import ModalButton from "../buttons/modal-button/modal-button";
import Form from "../form/form";
import Logo from "../logo/logo";

const Hero = () => {
    const classes = 'row expanded collapse ' + styles.hero;

    return (
        <div className={classes} id={'main'}>
            <div className="column" style={{position: "relative"}}>
                <div className="show-for-small hide-for-large" style={{position: "absolute", top: '30px', left: '15px'}}>
                    <Logo small={true}/>
                </div>
                <div className={styles.overflow}>
                    <div className="section-wrapper">
                        <div className="section">
                            <div className={styles.heroText}>
                                <p className={styles.heading}>
                                    Ремонт и отделка квартир в Минске
                                </p>
                                <p className={styles.details}>
                                    Ремонт и отделка квартир под ключ в Минске. Черновые и чистовые работы. <br />
                                    Разработка дизайна вашего жилища. От комнаты до квартиры и этажа. <br />
                                    Качественный контроль и организация ремонта от начала до конца.
                                </p>
                                <ModalButton
                                    text={'Заказать консультацию'}
                                    content={<Form isPopup={true}/>} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Hero
