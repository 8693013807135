import React from "react"
import Modal from 'react-modal';
import styles from "../../buttons/button.module.css";

const GalleryPopup = ({text, btnClass, content}) => {

    const customStyles = {
        overlay: {
            zIndex: 1001,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        content: {
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            overflow: 'visible',
            background: 'none',
            padding: 0,
            border: 'none',
            width: '100%',
            height: '100%'
        }
    };

    Modal.setAppElement('#___gatsby');

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {

    }

    function closeModal(){
        setIsOpen(false);
    }

    return (
        <div>
            <button className={btnClass ? btnClass : styles.button} onClick={openModal}>{text}</button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >

                <div className="request-modal" style={{height: "100%"}}>
                    <a
                        className="close-modal"
                        style={{
                            right: "40px",
                            top: "40px",
                            fontSize: "42px",
                            zIndex: "1000",
                            position: "fixed"
                        }}
                        onClick={closeModal}>
                        &times;
                    </a>
                    {content}
                </div>
            </Modal>
        </div>
    );
};


export default GalleryPopup;
