import React from "react"
import styles from "./article.module.css"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Article = ({background, text}) => {

    const sectionStyle = {
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    };

    const data = useStaticQuery(graphql`
      query {
        link: file(relativePath: { eq: "link-icon.png" }) {
          childImageSharp {
            fixed(width: 25, height: 17) {
              ...GatsbyImageSharpFixed
            }
          }
        },
      }
    `);

    return (
        <div className={styles.article}>
            <div className={styles.image} style={sectionStyle}>
                <div className={styles.imageBackground}>
                    <span className={styles.imageText}>
                        {text}
                    </span>
                    <div className={styles.link}>
                        <Img fixed={data.link.childImageSharp.fixed} />
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Article
