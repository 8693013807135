import React from "react"
import styles from"./mobile-menu.module.css"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import useWindowDimensions from "./window-dimensions";
import Logo from "../logo/logo";
import MenuItems from "../header/menuItems/menuItems";
import Contacts from "../contacts/contacts";
import Form from "../form/form";
import ModalButton from "../buttons/modal-button/modal-button";


const MobileMenu = () => {

    const data = useStaticQuery(graphql`
    query {
      mobileMenu: file(relativePath: { eq: "mobile-menu-icon.png" }) {
        childImageSharp {
          fixed(width: 55, height: 55) {
          ...GatsbyImageSharpFixed
          }
        }
      },
      closeMobileMenu: file(relativePath: { eq: "close-mobile-menu-icon.png" }) {
        childImageSharp {
          fixed(width: 55, height: 55) {
          ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

    const { width, height } = useWindowDimensions();
    const menuWidth = 285;

    const [isMenuActive, setMenuActive] = React.useState(false);
    const [menuOffset, setMenuOffset] = React.useState(-16 - menuWidth);

    const [menuBackgroundStyles, setMenuBackgroundStyles] = React.useState({
        width: width,
        height: height,
        visibility: 'hidden',
        opacity: 0
    })

    const menuClasses = "row show-for-small hide-for-large " + styles.mobileMenu + ' ' + (isMenuActive ? styles.active : '');
    const menuContentClasses = styles.mobileMenuContent;

    function handleMobileMenuOpen() {
        setMenuActive(!isMenuActive);
        setMenuOffset(-16);
        setMenuBackgroundStyles({
            width: width,
            height: height,
            visibility: 'visible',
            opacity: 0.7
        })
    }

    function handleMobileMenuClose() {
        setMenuActive(!isMenuActive);
        setMenuOffset(-16 - menuWidth);
        setMenuBackgroundStyles({
            width: width,
            height: height,
            visibility: 'hidden',
            opacity: 0
        })
    }

    return (
        <div className={menuClasses}>
            <div onClick={handleMobileMenuOpen}>
                <Img fixed={data.mobileMenu.childImageSharp.fixed} />
            </div>
            <div className={styles.mobileMenuBackground} style={menuBackgroundStyles} onClick={handleMobileMenuClose}></div>
            <div className={menuContentClasses} style={{width: menuWidth, right: menuOffset}}>
                <div className="row">
                    <div className="columns small-12">
                        <Logo black={true} small={true}/>
                    </div>
                    <div className={styles.mobileMenuBtnClose} onClick={handleMobileMenuClose}>
                        <Img fixed={data.closeMobileMenu.childImageSharp.fixed} />
                    </div>
                </div>
                <div className={styles.menuWrapper}>
                    <MenuItems isMobile={true} handleClick={handleMobileMenuClose}/>
                </div>
                <div className={styles.contactsWrapper}>
                    <div className="row">
                        <div className="columns small-12">
                            <Contacts isBlack={true}/>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '30px'}}>
                        <div className="columns small-12">
                            <ModalButton
                                text={'Заказать консультацию'}
                                content={<Form isPopup={true}/>} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu
