import React from "react"
import styles from "./calculator.module.css"
import Title from "../title/title";
import Option from "./option/option";
import ModalButton from "../buttons/modal-button/modal-button";
import Form from "../form/form";

const Calculator = () => {

    const rootClasses  = 'row expanded ' + styles.calculator;
    const calcOptionsWrapper = 'row expanded ' + styles.calcOptionsWrapper;
    const calcSummaryClasses = 'row align-middle ' + styles.summary;
    const base = 100;
    const options = [
        {
            id: 1,
            text: 'Простой',
            selected: true,
            type: 'design',
            multiplier: 1
        },
        {
            id: 2,
            text: 'Сложный',
            selected: false,
            type: 'design',
            multiplier: 1.3
        },
        {
            id: 3,
            text: 'Дизайнерский',
            selected: false,
            type: 'design',
            multiplier: 1.5
        },
        {
            id: 4,
            text: '1-а комнатная',
            selected: false,
            type: 'rooms',
            multiplier: 1.35
        },
        {
            id: 5,
            text: '2-x комнатная',
            selected: false,
            type: 'rooms',
            multiplier: 1.1
        },
        {
            id: 6,
            text: '3-x комнатная',
            selected: true,
            type: 'rooms',
            multiplier: 1
        },
        {
            id: 7,
            text: 'Новостройка',
            selected: true,
            type: 'flat',
            multiplier: 1
        },
        {
            id: 8,
            text: 'Вторичная',
            selected: false,
            type: 'flat',
            multiplier: 1.3
        },
        {
            id: 9,
            text: 'Панельный',
            selected: false,
            type: 'walls',
            multiplier: 0.9
        },
        {
            id: 10,
            text: 'Монолитный',
            selected: false,
            type: 'walls',
            multiplier: 1
        },
        {
            id: 11,
            text: 'Кирпич',
            selected: true,
            type: 'walls',
            multiplier: 1.3
        }
    ];

    const [selectedDesign, setSelectedDesign] = React.useState(1);
    const [selectedRooms, setSelectedRooms] = React.useState(6);
    const [selectedFlat, setSelectedFlat] = React.useState(7);
    const [selectedWalls, setSelectedWalls] = React.useState(11);
    const [square, setSquare] = React.useState(50);
    const [height, setHeight] = React.useState(250);
    const [sum, setSum] = React.useState(base);

    React.useEffect(updateCalc);

    function getOptionsByType(type) {
        return options.filter(opt => opt.type === type);
    }

    function handleSelectDesign(selectedId) {
        setSelectedDesign(selectedId);
    }

    function handleSelectRooms(selectedId) {
        setSelectedRooms(selectedId);
    }

    function handleSelectFlat(selectedId) {
        setSelectedFlat(selectedId);
    }

    function handleSelectWalls(selectedId) {
        setSelectedWalls(selectedId);
    }

    function handleSquareChange(e) {
        setSquare(e.target.value);
    }

    function handleHeightChange(e) {
        setHeight(e.target.value);
    }

    function updateCalc() {
        const selectedOptions = [];
        let calcResult = base;
        options
            .filter(opt => [selectedDesign, selectedRooms, selectedFlat, selectedWalls].indexOf(opt.id) !== -1)
            .map(opt => selectedOptions.push(opt));
        selectedOptions.forEach(opt => {
            calcResult = calcResult * opt.multiplier;
        });
        let heightMultiplier = 1;
        if (height > 260 && height <= 280) {
            heightMultiplier = 1.15;
        } else if (height > 280 && height <= 300) {
            heightMultiplier = 1.25;
        } else if (height > 300) {
            heightMultiplier = 1.35;
        }
        let squareNumber = isNaN(square) ? 50 : square;
        setSum(Math.ceil(calcResult * squareNumber * heightMultiplier / 100) * 100);
    }

    return (
        <div className={rootClasses} id={"calculator"}>
            <div className="column small-12">
                <div className="section-wrapper">
                    <div className="section">
                        <Title text={'Калькулятор стоимости работ'} color={'#393939'} />
                        <div className="row expanded align-center">
                            <div className="column small-12 small-text-left medium-8 medium-text-center">
                                <p className={styles.smallText}>
                                    Простой калькулятор для расчёта примерной стоимости работ. У нас было много проектов и исходя из опыта мы можем показать примерную стоимость услуг. Если каких то вариантов нет, просто позвоните нам или закажите консультацию и мы перезвоним вам.
                                    <span style={{fontWeight: "bold"}}> В стоимость работ уже включены расходы на черновые материалы!</span>
                                </p>
                            </div>
                        </div>
                        <div className={calcOptionsWrapper}>
                            <div className="column small-12">
                                <div className="row expanded">
                                    <div className="columns small-12 medium-6 large-3">
                                <span className={styles.optionsTitle}>
                                    1. Категория ремонта
                                </span>
                                        <Option options={getOptionsByType('design')} handler={handleSelectDesign}/>
                                    </div>
                                    <div className="columns small-12 medium-6 large-3">
                                <span className={styles.optionsTitle}>
                                    2. Выберите обьект:
                                </span>
                                        <Option options={getOptionsByType('rooms')} handler={handleSelectRooms}/>
                                    </div>
                                    <div className="columns small-12 medium-6 large-3">
                                <span className={styles.optionsTitle}>
                                    3. Вид постройки:
                                </span>
                                        <Option options={getOptionsByType('flat')} handler={handleSelectFlat}/>
                                    </div>
                                    <div className="columns small-12 medium-6 large-3">
                                <span className={styles.optionsTitle}>
                                    4. Материал стен:
                                </span>
                                        <Option options={getOptionsByType('walls')} handler={handleSelectWalls}/>
                                    </div>
                                </div>
                                <div className="row show-for-large" style={{marginBottom: '65px'}}/>
                                <div className="row expanded">
                                    <div className="columns small-12 medium-6 large-3">
                                <span className={styles.optionsTitle}>
                                    5. Площадь помещения
                                </span>
                                        <div className={styles.inputWrapper}>
                                            <div className={styles.inputText}>
                                                м2
                                            </div>
                                            <input type="text" className={styles.input} onChange={handleSquareChange} value={square}/>
                                        </div>
                                    </div>
                                    <div className="columns small-12 medium-6 large-3">
                                <span className={styles.optionsTitle}>
                                    6. Высота потолков:
                                </span>
                                        <div className={styles.inputWrapper}>
                                            <div className={styles.inputText}>
                                                см
                                            </div>
                                            <input type="text" className={styles.input} onChange={handleHeightChange} value={height}/>
                                        </div>
                                    </div>
                                    <div className="columns small-12 medium-8 large-6">
                                        <div className={calcSummaryClasses}>
                                            <div className="columns small-12 medium-5">
                                                <p className={styles.summaryText}>Средняя цена работ:</p>
                                                <p className={styles.summaryPrice}>{sum} $</p>
                                            </div>
                                            <div className="columns small-12 medium-7 small-text-left medium-text-center">
                                                <ModalButton
                                                    text={'Заказать консультацию'}
                                                    content={<Form isPopup={true}/>} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Calculator
