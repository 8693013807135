import styles from "./form.module.css";
import React from "react";
import ActionButton from "../buttons/action-button/action-button";
import axios from "axios";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Form = ({isPopup}) => {

    const data = useStaticQuery(graphql`
      query {
        success: file(relativePath: { eq: "success-icon.png" }) {
          childImageSharp {
            fixed(width: 72, height: 48) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `);

    const [name, setName] = React.useState();
    const [invalidName, setInvalidName] = React.useState(false);
    const [phone, setPhone] = React.useState();
    const [invalidPhone, setInvalidPhone] = React.useState(false);
    const [message, setMessage] = React.useState();
    const [invalidMessage, setInvalidMessage] = React.useState(false);
    const [formVisible, setFormVisible] = React.useState(true);

    function handleNameChange(e) {
        setInvalidName(false);
        setName(e.target.value);
    }

    function handlePhoneChange(e) {
        setInvalidPhone(false);
        setPhone(e.target.value);
    }

    function handleMessageChange(e) {
        setInvalidMessage(false);
        setMessage(e.target.value);
    }

    function sendEmail() {
        if (!validate()) {
            return false;
        }
        let bodyFormData = new FormData();
        bodyFormData.set('name', name);
        bodyFormData.set('phone', phone);
        bodyFormData.set('message', message);
        axios({
            method: 'post',
            url: '/sendMail.php',
            data: bodyFormData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
            .then(function (response) {
                setFormVisible(false);
            })
            .catch(function (response) {
                setFormVisible(false);
            });
    }

    function validate() {
        let isValid = true;
        if (!name) {
            setInvalidName(true);
            isValid = false;
        }
        if (!phone) {
            setInvalidPhone(true);
            isValid = false;
        }
        if (!message) {
            setInvalidMessage(true);
            isValid = false;
        }
        return isValid;
    }

    return (
        <div className={styles.contactForm}>
            <div className={formVisible ? '' : 'hide'}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>
                        <span className={styles.stick}/><span className={styles.text} style={{color: isPopup ? "#393939" : "#fff"}}>Оставьте заявку и мы перезвоним</span>
                    </div>
                </div>
                <input type={'text'} className={styles.input} style={{backgroundColor: invalidName ? "#ff6961" : ''}} placeholder={'Ваше имя'} onChange={handleNameChange}/>
                <input type={'text'} className={styles.input} placeholder={'Номер телефона'} style={{backgroundColor: invalidPhone ? "#ff6961" : ''}} onChange={handlePhoneChange}/>
                <textarea className={styles.textarea} placeholder={'Опишите кратко какие работы вас интересуют'} style={{backgroundColor: invalidMessage ? "#ff6961" : ''}} onChange={handleMessageChange}/>
                <ActionButton text={'Отправить заявку'} action={sendEmail}/>
            </div>
            <div className={formVisible ? 'hide' : ''}>
                <p className={styles.successTitle} style={{color: isPopup ? "#393939" : "#fff"}}>
                    Ура. Заявка отправлена.
                </p>
                <div className={styles.successContent}>
                    <div className={styles.successIcon}>
                        <Img fixed={data.success.childImageSharp.fixed}/>
                    </div>
                    <p className={styles.successText}>
                        Ура. Заявка отправлена. <br/>
                        Мы скоро свяжемся с вами
                    </p>
                </div>
                <div className={isPopup ? '' : 'hide'}>
                    <ActionButton text={'Вернуться на сайт'} action={() => {window.location.reload(true)}}/>
                </div>
            </div>
        </div>
    );
};

export default Form
