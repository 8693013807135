import React from "react"
import styles from "./menuItems.module.css"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {Link} from "gatsby";
import { navigate } from "gatsby"

const MenuItems = ({isMobile = false, handleClick = () => {}}) => {

    const menuItems = [
        {name: 'Галерея работ', selected: true, id: 'gallery'},
        {name: 'Почему мы', selected: false, id: 'about'},
        {name: 'Калькулятор', selected: false, id: 'calculator'},
        {name: 'Наши услуги', selected: false, id: 'services'},
        // {name: 'Отзывы', selected: false, id: 'feedback'},
        {name: 'Статьи', selected: false, id: 'articles'}
    ];

    const list = [];

    for (const [index, value] of menuItems.entries()) {
        let selected = value.selected ? 'selected' : '';
        let itemClasses = selected + ' ' + styles.item;
        list.push(
            <li key={index} className={itemClasses}>
                {isMobile ?
                    <a onClick={event => {
                        event.preventDefault();
                        handleClick();
                        navigate('#' + value.id)
                    }} >{value.name}</a>
                    :
                    <AnchorLink offset='105' href={'#' + value.id}>{value.name}</AnchorLink>
                }
            </li>
        )
    }

    return (
        <ul className={(isMobile ? "vertical " : "") + "menu"} style={{display: 'flex', justifyContent: 'center'}}>
            {list}
        </ul>
    )
};


export default MenuItems
