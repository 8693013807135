import React from "react"
import styles from "./flat.module.css"
import ImageGallery from 'react-image-gallery';
import GalleryPopup from "../popup/gallery-popup";

const Flat = ({background, text, panoramaLink, images}) => {

    const sectionStyle = {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: "center"
    };
    const panoramaClasses = 'panorama-button' + ((panoramaLink === undefined) ? ' hide' : '');
    const rootClasses = "column small-12 medium-6 large-4";
    return (
        <div className={rootClasses} style={{marginTop: "30px"}}>
            <div className={styles.flat}>
                <div className={styles.image} style={sectionStyle}>
                    <div className={styles.imageBackground}>
                        <span className={styles.imageText}>
                            {text}
                        </span>
                    </div>
                </div>
                <div className={styles.buttonsWrapper}>
                    <div className={images === undefined ? 'hide' : ''} style={{marginRight: "20px"}}>
                        <GalleryPopup text={'Смотреть фото'} content={
                            <div style={{width: "100%", height: "100%"}}>
                                <ImageGallery
                                    items={images}
                                    showPlayButton={false}
                                    showFullscreenButton={false}
                                />
                            </div>
                        }/>
                    </div>
                    <a href={panoramaLink}>
                        <div className={panoramaClasses}/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Flat
