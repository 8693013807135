import React from "react"
import styles from "./service.module.css"

const Service = ({index, text}) => {

    return (
        <div className={styles.service}>
            <div className={styles.index}>
                <span>{index}</span>
                <div className={styles.textWrapper}>
                    <span className={styles.stick}/><span className={styles.text}>{text}</span>
                </div>
            </div>
        </div>
    );
};

export default Service
