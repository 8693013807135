import React from "react"
import styles from "./gallery.module.css"
import Title from "../title/title";
import Flat from "./flat/flat";
import NewBorImages from "../../content/newbor-images.json"
import PobediteleyImages from "../../content/pobediteley-images.json"
import VostochnayaImages from "../../content/vostochnaya-images.json"
import LebyazhyImages from "../../content/lebyazhy-images.json"
import FlatImages from "../../content/flat-images.json"

const Gallery = () => {

    const galleryClasses = 'row expanded ' + styles.galleryWrapper;
    const flatBlockWrapper = 'row expanded ' + styles.flatBlockWrapper;

    return (
        <div className={galleryClasses} id={"gallery"}>
            <div className="column">
                <div className="section-wrapper">
                    <div className="section">
                        <Title text={'Галерея наших работ'} color={'#000'} />
                        <div className="row expanded">
                            <Flat
                                background={"/images/kvartira-1/background/bg.jpg"}
                                text={'Ремонт квартиры пр. Победителей'}
                                images={PobediteleyImages}
                                panoramaLink={"/panorama/kvartira-1/pano-1"}
                            />
                            <Flat
                                background={"/images/kvartira-2/background/bg.jpg"}
                                text={'Панорама квартиры ул. Беды'}
                                panoramaLink={"/panorama/kvartira-2/pano-1"}
                            />
                            <Flat
                                background={"/images/kvartira-3/background/bg.jpg"}
                                text={'Панорама квартиры ул. Мястровская'}
                                panoramaLink={"/panorama/kvartira-3/pano-1"}
                            />
                            <Flat
                                background={"/images/kvartira-4/background/bg.jpg"}
                                text={'Панорамы квартиры пр. Независимости'}
                                panoramaLink={"/panorama/kvartira-4/pano-1"}
                            />
                            <Flat
                                background={"/images/kvartira-5/background/bg.jpg"}
                                text={'Панорама квартиры ул. Полевая'}
                                panoramaLink={"/panorama/kvartira-5/pano-1"}
                            />
                            <Flat
                                background={"/images/kvartira-6/background/bg.jpg"}
                                text={'Ремонт квартиры ул. Восточная'}
                                images={VostochnayaImages}
                                panoramaLink={"/panorama/kvartira-6/pano-1"}
                            />
                            <Flat
                                background={"/images/kvartira-7/background/bg.jpg"}
                                text={'Ремонт квартиры в Новой Боровой'}
                                images={NewBorImages}
                            />
                            <Flat
                                background={"/images/kvartira-8/background/bg.jpg"}
                                text={'Ремонт квартиры ул. Радужная'}
                                images={LebyazhyImages}
                            />
                            <Flat
                                background={"/images/kvartira-9/background/bg.jpg"}
                                text={'Ремонт квартиры ул. Лазо'}
                                images={FlatImages}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Gallery
